<template>
  <div class="MyTv">
    <Spacer num="1" />
    <Title :label="$locale['watch']" :class="{ xpadding: $isMobile || $isTablet }" />
    <Spacer num="1" />
    <div class="MyTvList">
      <DynamicScroller class="scroller" :items="items" v-bind="scroller">
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.size]" :data-index="index">
            <TvMedia :media="item" :key="item.id" :index="index" />
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
      <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
      <div v-if="!$loading && !statistics.total && listed">
        <CardMessage :message="$locale['nothing_found']" />
        <Spacer num="1" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  components: {
    TvMedia: () => import("./TvMedia.vue"),
  },
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/posts/tv`,
      storeId: "my_tv",
      items: [],
      statistics: { total: 0, page: 1 },
      limit: 20,
      scroller: {
        buffer: 1080,
        "min-item-size": 200,
        "key-field": "id",
        "page-mode": true,
      },
    };
  },
};
</script>

<style lang="scss">
.MyTv {
  position: relative;
}
</style>
